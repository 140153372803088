import React from "react"
// reactstrap components
import {
  Card,
  Container,
  Row,
  CardHeader,
  CardBody,
  TabPane,
  TabContent,
  NavItem,
  Nav,
  Col,
  Button,
  Input,
  Label,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import Header from "components/Headers/Header"
import { getForm } from "booking-shared/fb"
import { NavLink } from "react-router-dom"
import classNames from "classnames"
import _ from "lodash"
import { Field, Form, Formik } from "formik"
import {
  DateTimeInterval,
  dateToInput,
  todayEnd,
  todayStart,
} from "booking-shared/DateUtils"
import FormInput from "components/FormInput"
import FormModel from "booking-shared/models/FormModel"
import { addSyntheticLeadingComment } from "typescript"

class FormSettings extends React.Component {
  state = {
    tabs: 1,
    form: null,
    focusedElement: null,
    colAddOpen: false,
  }

  async componentDidMount() {
    this.setState({
      form: await FormModel.load(this.props.match.params.id),
    })
    // this.prepareTimeintervals()
  }

  makeid(length) {
    var result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault()
    this.setState({
      [state]: index,
    })
  }

  _addBlockedTime() {
    const { form } = this.state
    form.blockedTimes.push({
      start: dateToInput(todayStart()),
      end: dateToInput(todayEnd()),
    })
    this.setState({
      form,
    })
  }

  _removeBlockedTime(index) {
    const { form } = this.state
    form.blockedTimes.splice(index, 1)
    this.setState({
      form,
    })
  }

  prepareTimeintervals = () => {
    if (this.state.form) {
      const timeIntervals = this.state.form.timeIntervals.map(
        (timeInterval) => {
          const interval = new DateTimeInterval(
            timeInterval.start.toDate(),
            timeInterval.end.toDate()
          )
          return {
            ...timeInterval,
            startTime: interval.startTimeToString(),
            endTime: interval.endTimeToString(),
          }
        }
      )
      const form = this.state.form
      form.timeIntervals = timeIntervals
      this.setState({
        form,
      })
    }
  }

  renderEmail() {
    const { form } = this.state
    return (
      <Formik
        initialValues={form}
        validate={(values) => {
          const errors = {}
          return errors
        }}
        onSubmit={async (data) => {
          await FormModel.save(this.props.match.params.id, data)
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Row>
              <Col>
                <FormInput
                  tag={Field}
                  type="text"
                  name="emailAdminName"
                  label="Sender Name"
                />
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Label for="emailClientRequestTemplate">
                  Vorlage - Anfrage Email (Kunde)
                </Label>
                <Field name="emailClientRequestTemplate">
                  {({ field }) => (
                    <Input {...field} type="textarea" style={{ height: 300 }} />
                  )}
                </Field>
              </Col>
              <Col>
                <Label for="emailClientRequestTemplate">
                  Vorlage - Bestätigungs Email (Kunde)
                </Label>
                <Field name="emailClientConfirmTemplate">
                  {({ field }) => (
                    <Input {...field} type="textarea" style={{ height: 300 }} />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label for="emailAdminRequestTemplate">
                  Vorlage - Anfrage Email (Admin)
                </Label>
                <Field name="emailAdminRequestTemplate">
                  {({ field }) => (
                    <Input {...field} type="textarea" style={{ height: 300 }} />
                  )}
                </Field>
              </Col>
              <Col>
                <Label for="emailAdminRequestTemplate">
                  Vorlage - Bestätigungs Email (admin)
                </Label>
                <Field name="emailAdminConfirmTemplate">
                  {({ field }) => (
                    <Input {...field} type="textarea" style={{ height: 300 }} />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="submit">Speichern</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    )
  }

  renderTimes() {
    const { form } = this.state
    console.log(form)
    return (
      <Formik
        initialValues={form}
        validate={(values) => {
          const errors = {}
          return errors
        }}
        onSubmit={async (data) => {
          await FormModel.save(this.props.match.params.id, data)
        }}
      >
        {({ errors, touched, setFieldValue, get, values }) => (
          <Form>
            <Row>
              <Col>
                <h2>Wochentage</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  tag={Field}
                  type="checkbox"
                  name={`weekdays[1]`}
                  checked={values.weekdays[1]}
                  //onChange={(e) => setFieldValue(`weekdays[1]`, false)}
                  label="Montag"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  type="checkbox"
                  name={`weekdays[2]`}
                  checked={values.weekdays[2]}
                  label="Dienstag"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  type="checkbox"
                  name={`weekdays[3]`}
                  checked={values.weekdays[3]}
                  label="Mittwoch"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  type="checkbox"
                  name={`weekdays[4]`}
                  checked={values.weekdays[4]}
                  label="Donnerstag"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  type="checkbox"
                  name={`weekdays[5]`}
                  checked={values.weekdays[5]}
                  label="Freitag"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  type="checkbox"
                  name={`weekdays[6]`}
                  checked={values.weekdays[6]}
                  label="Samstag"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  type="checkbox"
                  name={`weekdays[0]`}
                  checked={values.weekdays[7]}
                  label="Sonntag"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <FormInput
                  tag={Field}
                  type="checkbox"
                  name={"blockWholeDay"}
                  checked={values.blockWholeDay}
                  label="Blockiere gesamten Tag bei mindestens einer Buchung"
                />
              </Col>
              <Col xs={2}>
                <FormInput
                  tag={Field}
                  required
                  type="number"
                  name="maxBookingsPerDay"
                  label="Max. Buchungen pro Tag"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  required
                  type="time"
                  name="timeIntervals[0].start.time"
                  label="Startzeit"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  required
                  type="time"
                  name="timeIntervals[0].duration"
                  label="Dauer"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  required
                  type="time"
                  name="timeIntervals[0].step"
                  label="Schrittweite"
                />
              </Col>

              <Col></Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <h2>Blockierte Zeiten</h2>
                {form.blockedTimes.map((item, i) => (
                  <Row>
                    <Col>
                      <FormInput
                        tag={Field}
                        required
                        type="date"
                        name={`blockedTimes[${i}].start.date`}
                      />
                    </Col>
                    <Col>
                      <FormInput
                        tag={Field}
                        required
                        type="time"
                        name={`blockedTimes[${i}].start.time`}
                      />
                    </Col>
                    <Col>
                      <FormInput
                        tag={Field}
                        required
                        type="date"
                        name={`blockedTimes[${i}].end.date`}
                      />
                    </Col>
                    <Col>
                      <FormInput
                        tag={Field}
                        required
                        type="time"
                        name={`blockedTimes[${i}].end.time`}
                      />
                    </Col>
                    <Col>
                      <Button onClick={() => this._removeBlockedTime(i)}>
                        Entfernen
                      </Button>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                ))}
                <Row>
                  <Col>
                    <Button onClick={() => this._addBlockedTime()}>
                      Blockierten Zeitraum hinzufügen
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type="submit">Speichern</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    )
  }

  renderLayoutDesigner() {
    const { form, colAddOpen } = this.state

    console.log(this.state.focusedElement)
    console.log(form)

    const findFieldIndex = (fieldId) => {
      return form.fields.findIndex((field) => field.id === fieldId)
    }

    const renderFieldEditor = (rowIndex, colIndex, fieldIndex, fieldname) => {
      return (
        <Card>
          <CardBody>
            <Row>
              <Col>
                {" "}
                <FormInput
                  type="select"
                  name={`fields[${findFieldIndex(fieldname)}].type`}
                  label="Feldtyp"
                >
                  <option value="text">Textfeld</option>
                </FormInput>
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  type="text"
                  name={`fields[${findFieldIndex(fieldname)}].name`}
                  label="Feldname"
                />
              </Col>
              <Col>
                <FormInput
                  tag={Field}
                  type="text"
                  name={`fields[${findFieldIndex(fieldname)}].label`}
                  label="Beschriftung"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  onClick={() => removeElement(rowIndex, colIndex, fieldname)}
                >
                  entfernen
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )
    }

    const addElement = (rowIndex, colIndex) => {
      const newForm = _.cloneDeep(form)
      const newFieldId = this.makeid(6)
      newForm.fields.push({
        id: newFieldId,
        type: "text",
        name: "",
        label: "",
      })
      const newLayout = form.layout
      newLayout[rowIndex][colIndex].push(newFieldId)
      newForm.layout = newLayout
      this.setState({ form: newForm })
    }

    const removeElement = (rowIndex, colIndex, id) => {
      const newForm = _.cloneDeep(form)
      const elements = form.layout[rowIndex][colIndex].filter((e) => e !== id)
      const fields = form.fields.filter((e) => e.id !== id)
      newForm.fields = fields
      newForm.elements = elements
      this.setState({
        form: newForm,
      })
    }

    const addCol = (num) => {
      form.layout.push(Array(num).fill([]))
      this.setState({ form })
    }

    return (
      <Formik
        initialValues={form}
        validate={(values) => {
          const errors = {}
          return errors
        }}
        onSubmit={async (data) => {
          await FormModel.save(this.props.match.params.id, data)
        }}
      >
        {({ errors, touched }) => (
          <Form>
            {form &&
              form.layout &&
              form.layout.map((row, rowIndex) => {
                return (
                  <>
                    <Card>
                      <CardBody>
                        <Row key={`row_${rowIndex}`}>
                          {row.map((col, colIndex) => (
                            <Col key={`col_${colIndex}`}>
                              {col.map((fieldname, fieldIndex) => (
                                <>
                                  {renderFieldEditor(
                                    rowIndex,
                                    colIndex,
                                    fieldIndex,
                                    fieldname
                                  )}
                                </>
                              ))}
                              <Row>
                                <Col>
                                  <br />
                                  <Button
                                    onClick={() =>
                                      addElement(rowIndex, colIndex)
                                    }
                                  >
                                    Formularelement hinzufügen
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </Row>
                      </CardBody>
                    </Card>
                  </>
                )
              })}
            <Row>
              <Col>
                <br />
                <Button onClick={() => addCol(1)}>+ 1 Spalte</Button>
                <Button onClick={() => addCol(2)}>+ 2 Spalten</Button>
                <Button type="submit">Speichern</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    )
  }

  renderDesigner() {
    const { form } = this.state
    return (
      <Formik
        initialValues={form}
        validate={(values) => {
          const errors = {}
          return errors
        }}
        onSubmit={async (data) => {
          await FormModel.save(this.props.match.params.id, data)
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Row>
              <Col>
                <Label for="design">CSS</Label>
                <Field name="design">
                  {({ field }) => (
                    <Input {...field} type="textarea" style={{ height: 300 }} />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="submit">Speichern</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    )
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                {!this.state.form && "lade ..."}
                {this.state.form && (
                  <>
                    <CardHeader className="border-0">
                      <h3 className="mb-0">Formular {this.state.form.name}</h3>
                    </CardHeader>
                    <CardBody>
                      <div className="nav-wrapper">
                        <Nav
                          className="nav-fill flex-column flex-md-row nav-pills"
                          id="tabs-icons-text"
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              aria-selected={this.state.tabs === 1}
                              className={classNames(
                                "mb-sm-3 mb-md-0 nav-link",
                                {
                                  active: this.state.tabs === 1,
                                }
                              )}
                              onClick={(e) => this.toggleNavs(e, "tabs", 1)}
                              to="./t1"
                              role="tab"
                            >
                              <i className="fas fa-clock"></i> Zeiten
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-selected={this.state.tabs === 2}
                              className={classNames(
                                "mb-sm-3 mb-md-0 nav-link",
                                {
                                  active: this.state.tabs === 2,
                                }
                              )}
                              onClick={(e) => this.toggleNavs(e, "tabs", 2)}
                              to="./t1"
                              role="tab"
                            >
                              <i className="fas fa-envelope"></i> Email
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-selected={this.state.tabs === 3}
                              className={classNames(
                                "mb-sm-3 mb-md-0 nav-link",
                                {
                                  active: this.state.tabs === 3,
                                }
                              )}
                              onClick={(e) => this.toggleNavs(e, "tabs", 3)}
                              to="./t1"
                              role="tab"
                            >
                              <i className="fab fa-wpforms"></i> Layout
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-selected={this.state.tabs === 4}
                              className={classNames(
                                "mb-sm-3 mb-md-0 nav-link",
                                {
                                  active: this.state.tabs === 4,
                                }
                              )}
                              onClick={(e) => this.toggleNavs(e, "tabs", 4)}
                              to="./t1"
                              role="tab"
                            >
                              <i className="fas fa-palette"></i> Design
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                      <Card className="shadow">
                        <CardBody>
                          <TabContent activeTab={"tabs" + this.state.tabs}>
                            <TabPane tabId="tabs1">
                              {this.renderTimes()}
                            </TabPane>
                            <TabPane tabId="tabs2">
                              {this.renderEmail()}
                            </TabPane>
                            <TabPane tabId="tabs3">
                              {this.renderLayoutDesigner()}
                            </TabPane>
                            <TabPane tabId="tabs4">
                              {this.renderDesigner()}
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    </CardBody>
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

export default FormSettings
