/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button,
  FormGroup,
  Input,
  Col,
  Form,
  Label,
} from "reactstrap"
// core components
import Header from "components/Headers/Header.js"
import React, { useEffect, useState } from "react"
import Dialog from "components/Dialogs/Dialog"
import { useForm } from "react-hook-form"
import { addForm, getForms, auth } from "booking-shared/fb"
import { useAuthState } from "react-firebase-hooks/auth"
import { Link, useHistory } from "react-router-dom"

const Forms = () => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm()

  const [user, loading, error] = useAuthState(auth)

  const [showNewForm, setNewForm] = useState(false)
  const [forms, setForms] = useState([])
  const [inputName, setInputName] = useState("")
  const [inputType, setInputType] = useState("hours")
  const history = useHistory()

  const { ref: refName, ...registerName } = register("name")
  const { ref: refType, ...registerType } = register("type")

  useEffect(() => {
    getForms().then((f) => setForms(f))
  }, [])

  const getTableRow = (row) => (
    <tr key={row.id}>
      <th scope="row">
        <Link to={`/admin/forms/${row.id}`}>{row.data.name}</Link>
      </th>
      <td>
        {row.data.type === "course" && "vordefiniert"}
        {row.data.type === "hours" && "Stundenkalender"}
      </td>
      <td className="text-right">
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
              Action
            </DropdownItem>
            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
              Another action
            </DropdownItem>
            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
              Something else here
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  )

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Formulare</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Bezeichnung</th>
                    <th scope="col">Formulartyp</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>{forms.map((f) => getTableRow(f))}</tbody>
              </Table>
              <CardFooter className="py-4">
                <Button
                  aria-pressed={true}
                  className="active"
                  color="primary"
                  href="#pablo"
                  onClick={(e) => {
                    setNewForm(true)
                  }}
                  role="button"
                  size="sm"
                >
                  <i className="fas fa-plus"></i> Formular hinzufügen
                </Button>
                <Dialog
                  isOpen={showNewForm}
                  onCloseClicked={() => setNewForm(false)}
                  title="Formular hinzufügen"
                  okText={
                    <>
                      <i className="fas fa-plus"></i> Neues Formular erstellen
                    </>
                  }
                  onSaveClicked={async () => {
                    const values = getValues()
                    const form = await addForm(user, values.name, values.type)
                    if (form) {
                      history.push(`/admin/forms/${form.id}`)
                    }
                  }}
                >
                  <Form>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label for="name">Name</Label>
                          <Input
                            id="name"
                            type="text"
                            innerRef={refName}
                            {...registerName}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label for="type">Formular-Typ</Label>
                          <Input
                            id="type"
                            name="select"
                            type="select"
                            innerRef={refType}
                            {...registerType}
                          >
                            <option value="hours">
                              Formular mit Stundenkalender
                            </option>
                            <option values="days">
                              Formular mit Tageskalender
                            </option>
                            <option values="options">
                              Formular mit vordefinierten Optionen
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Dialog>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Forms
