import Moment from "moment/moment"
import _ from "lodash"
import "moment/locale/de"
import { extendMoment } from "moment-range"
import { getBlockedTimes } from "./fb"

const moment = extendMoment(Moment)

moment.locale("de")

export class DateTimeInterval {
  constructor(start, end) {
    this.start = (start && moment(start)) || moment()
    this.end = (end && moment(end)) || moment()
  }

  static fromDates(startDate, endDate) {
    return new DateTimeInterval(startDate, endDate)
  }

  getStart() {
    return this.start
  }

  getEnd() {
    return this.end
  }

  getStartAsDate() {
    return this.start.toDate()
  }

  getEndAsDate() {
    return this.end.toDate()
  }

  setTimeInterval(interval) {
    const bookingTimeRange = BookingTimeRange.parse(interval)
    this.start.set({
      hour: bookingTimeRange.getStartTime().toMoment().hours(),
      minute: bookingTimeRange.getStartTime().toMoment().minutes(),
      seconds: 0,
    })
    this.end.set({
      hour: bookingTimeRange.getEndTime().toMoment().hours(),
      minute: bookingTimeRange.getEndTime().toMoment().minutes(),
      seconds: 0,
    })
  }

  setStartTime(time) {
    let m = null
    if (moment.isDate(time)) {
      m = moment(time)
    }
    if (_.isString(time)) {
      m = BookingTime.parse(time).toMoment()
    }
    this.start.set({
      hour: m.hours(),
      minute: m.minutes(),
    })
  }

  setEndTime(time) {
    let m = null
    if (moment.isDate(time)) {
      m = moment(time)
    }
    if (_.isString(time)) {
      m = BookingTime.parse(time).toMoment()
    }
    this.end.set({
      hour: m.hours(),
      minute: m.minutes(),
    })
  }

  setStartDate(date) {
    let m = moment(date)
    this.start.set({
      date: m.date(),
      month: m.month(),
      year: m.year(),
    })
  }

  setEndDate(date) {
    let m = moment(date)
    this.end.set({
      date: m.date(),
      month: m.month(),
      year: m.year(),
    })
  }

  startTimeToString() {
    return this.start.format("HH:mm")
  }

  endTimeToString() {
    return this.end.format("HH:mm")
  }

  startDateToString() {
    return this.start.format("LL")
  }

  endDateToString() {
    return this.end.format("LL")
  }

  isSameDay() {
    return this.start.isSame(this.end, "day")
  }

  isValid() {
    return this.start.isBefore(this.end)
  }

  toString() {
    if (this.isSameDay()) {
      return `${this.startDateToString()} ${this.startTimeToString()} - ${this.endTimeToString()}`
    }
    return `${this.startDateToString()} ${this.startTimeToString()} - ${this.endDateToString()} ${this.endTimeToString()}`
  }

  timeToString() {
    return `${this.startTimeToString()} - ${this.endTimeToString()}`
  }

  isOverlapping(interval) {
    /*console.log(this.start)
    console.log(this.end)
    console.log(interval.getStart())
    console.log(interval.getEnd())*/
    const range1 = moment.range(this.start, this.end)
    const range2 = moment.range(interval.getStart(), interval.getEnd())
    return range1.overlaps(range2)
  }
}

export class BlockingTimes {
  constructor(props) {
    this.formId = props.formId
    this.globalBlockingTimes = []
    this.localBlockingTimes = []
    this.reload()
  }

  updateFromBookings(bookings) {
    this.localBlockingTimes = []
    console.log(bookings)
    bookings.forEach((b) => {
      this.localBlockingTimes.push(new DateTimeInterval(b.start, b.end))
    })
    console.log("gg", this.localBlockingTimes)
  }

  reload() {
    getBlockedTimes().then(
      (t) =>
        (this.globalBlockingTimes = t.filter(t => t.data.formId === this.formId).map((t) => {
          return {
            rawData: t,
            interval: DateTimeInterval.fromDates(
              t.data.start.toDate(),
              t.data.end.toDate()
            ),
          }
        }))
    )
  }

  isDateBlocked(date) {
    return (
      this.globalBlockingTimes.filter(
        (t) =>
          t.rawData.data.wholeDay &&
          moment(t.interval.getStart()).isSame(date, "day")
      ).length > 0
    )
  }

  isOverlapping(interval) {
    for (const blockedTime of this.globalBlockingTimes) {
      if (blockedTime.interval.isOverlapping(interval)) {
        return true
      }
    }
    for (const localBlockedTime of this.localBlockingTimes) {
      if (localBlockedTime.isOverlapping(interval)) {
        return true
      }
    }
    return false
  }
}

export class BookingTime {
  constructor(hour = 0, minute = 0) {
    this.hour = hour
    this.minute = minute
  }

  static parse(time) {
    if (moment.isMoment(time)) {
      return new BookingTime(time.hours(), time.minutes())
    }
    return new BookingTime(
      parseInt(time.split(":")[0]),
      parseInt(time.split(":")[1])
    )
  }

  toString() {
    const m = moment()
    m.set({
      hour: this.hour,
      minute: this.minute,
      second: 0,
      millisecond: 0,
    })
    return m.format("HH:mm")
  }

  toMoment() {
    const m = moment()
    m.set({
      hour: this.hour,
      minute: this.minute,
      second: 0,
      millisecond: 0,
    })
    return m
  }

  toNumber() {
    const hours = this.hour
    const minutes = this.minute / 60
    return hours + minutes
  }
}

export class BookingTimeRange {
  constructor(startTime, endTime) {
    this.start = startTime
    this.end = endTime
  }

  static parse(timeRangeString) {
    const start = timeRangeString.split(" - ")[0]
    const end = timeRangeString.split(" - ")[1]
    const startTime = BookingTime.parse(start)
    const endTime = BookingTime.parse(end)
    return new BookingTimeRange(startTime, endTime)
  }

  getStartTime() {
    return this.start
  }

  getEndTime() {
    return this.end
  }

  toString() {
    return `${this.start.toString()} - ${this.end.toString()}`
  }
}

export class TimeRangeChecker {
  constructor(timeRanges) {
    this.timeRanges = timeRanges
    //console.log(this.timeRanges)
  }

  isOverlapping(newInterval) {
    const a = newInterval.start.toNumber()
    const b = newInterval.end.toNumber()

    for (const interval of this.timeRanges) {
      const c = interval.start.toNumber()
      const d = interval.end.toNumber()

      if (a < d && b > c) {
        console.log("This one overlap: ", newInterval)
        console.log("with interval: ", interval)
        console.log("----")
        return true
      }
    }

    return false
  }
}

export const generateTimeRangeEntries = (timeRangeTemplate, date) => {
  const mDate = (date && moment(date)) || moment()
  const startTime = moment(inputToDate(timeRangeTemplate.start)).set({
    date: mDate.date(),
    month: mDate.months(),
    year: mDate.year(),
  })
  const stepTime = BookingTime.parse(timeRangeTemplate.step).toMoment().set({
    date: mDate.date(),
    month: mDate.months(),
    year: mDate.year(),
  })
  const stopTime = moment(inputToDate(timeRangeTemplate.end)).set({
    date: mDate.date(),
    month: mDate.months(),
    year: mDate.year(),
  })
  const durationTime = BookingTime.parse(timeRangeTemplate.duration)
    .toMoment()
    .set({
      date: mDate.date(),
      month: mDate.months(),
      year: mDate.year(),
    })

  const getEndTime = (startTime) => {
    startTime = startTime.clone()
    startTime.add(durationTime.hours(), "hours")
    startTime.add(durationTime.minutes(), "minutes")
    return startTime
  }

  const getNextStartTime = (startTime) => {
    startTime = startTime.clone()
    startTime.add(stepTime.hours(), "hours")
    startTime.add(stepTime.minutes(), "minutes")
    return startTime
  }

  let lastStartTime = startTime
  let lastEndTime = null
  const timeRangeEntries = []

  while (lastEndTime === null || lastEndTime.isBefore(stopTime)) {
    const interval = DateTimeInterval.fromDates(
      lastStartTime.toDate(),
      getEndTime(lastStartTime).toDate()
    )
    timeRangeEntries.push(interval)
    lastStartTime = getNextStartTime(lastStartTime)
    lastEndTime = getEndTime(lastStartTime)
  }

  return timeRangeEntries
}

export const dateToInput = (date) => {
  const m = moment(date)
  return {
    date: m.format("YYYY-MM-DD"),
    time: m.format("HH:mm"),
  }
}

export const inputToDate = (input) => {
  return moment(`${input.date} ${input.time}`).toDate()
}

export const todayStart = () => {
  return moment().startOf("day").toDate()
}

export const todayEnd = () => {
  return moment().endOf("day").toDate()
}
