import React from "react"
// reactstrap components
import { Button, Modal } from "reactstrap"

class Dialog extends React.Component {
  render() {
    const { title, children, onSaveClicked, onCloseClicked, isOpen, okText } =
      this.props
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
          toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              <span aria-hidden={true} onClick={onCloseClicked}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <Button
              color="primary"
              size="sm"
              type="button"
              onClick={onSaveClicked}
            >
              {okText || "Save"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default Dialog
