import { dateToInput, inputToDate } from "../DateUtils"
import { getForm, saveForm } from "../fb"
import _ from "lodash"

export default class FormModel {
  static async load(id) {
    const form = await getForm(id)
    if (form.blockedTimes) {
      for (const blockedTime of form.blockedTimes) {
        blockedTime.start = dateToInput(blockedTime.start.toDate())
        blockedTime.end = dateToInput(blockedTime.end.toDate())
      }
    }
    if (form.timeIntervals) {
      for (const timeInterval of form.timeIntervals) {
        timeInterval.start = dateToInput(timeInterval.start.toDate())
        timeInterval.end = dateToInput(timeInterval.end.toDate())
      }
    }
    if (form.layout) {
      form.layout = JSON.parse(form.layout)
    }
    return form
  }

  static async save(id, f) {
    const form = _.cloneDeep(f)
    if (form.blockedTimes) {
      for (const blockedTime of form.blockedTimes) {
        if (
          blockedTime.start.date === blockedTime.end.date &&
          blockedTime.start.time === "00:00" &&
          blockedTime.end.time === "23:59"
        ) {
          blockedTime.wholeDay = true
        } else {
          blockedTime.wholeDay = false
        }
        blockedTime.start = inputToDate(blockedTime.start)
        blockedTime.end = inputToDate(blockedTime.end)
      }
    }
    if (form.timeIntervals) {
      for (const timeInterval of form.timeIntervals) {
        timeInterval.start = inputToDate(timeInterval.start)
        timeInterval.end = inputToDate(timeInterval.end)
      }
    }
    if (form.layout) {
      form.layout = JSON.stringify(form.layout, null, 2)
    }
    await saveForm(id, form)
  }

  static getLabel(form, name) {
    const field = form.fields.find((f) => f.name === name)
    if (field) {
      return field.label
    } else {
      return null
    }
  }
}
