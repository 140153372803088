import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { addForm, getForm } from "booking-shared/fb"
import { useForm } from "react-hook-form"
import { Field, useFormikContext } from "formik"

function FormInput(props) {
  const { label, name, ...inputProps } = props

  const { errors } = useFormikContext()

  return (
    <>
      <FormGroup check={props.type === "checkbox"}>
        {props.type !== "checkbox" && <Label for={name}>{label}</Label>}
        <Input invalid={errors && errors[name]} name={name} {...inputProps} />
        {props.type === "checkbox" && <Label for={name}>{label}</Label>}
        {errors && errors[name] && <FormFeedback>{errors[name]}</FormFeedback>}
      </FormGroup>
    </>
  )
}

export default FormInput
