import { auth } from "booking-shared/fb"
import { onAuthStateChanged } from "firebase/auth"
import { useEffect, useState } from "react"
import { createContext } from "react"

export const AuthContext = createContext()
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user)
    })
  }, [])

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  )
}
