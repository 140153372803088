/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap"
// core components
import Header from "components/Headers/Header.js"
import {
  getBookings,
  getForms,
  callConfirmBooking,
  callRejectBooking,
  addBooking,
  updateBooking,
} from "booking-shared/fb"

class Bookings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bookings: [],
      allBookings: [],
      filterMonth: 0,
      filterYear: 0,
      forms: null,
      showNewBookingDialog: false,
      newBooking: {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        form: null,
        values: {},
      },
      showUpdateBookingDialog: false,
      updateBooking: {
        id: null,
        status: null,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        form: null,
        values: {},
      },
    }
  }

  createBooking() {
    const { startDate, startTime, endDate, endTime, form, values } = this.state.newBooking
    if (!startDate || !startTime || !endDate || !endTime || !form) {
      alert("Bitte alle Felder ausfüllen")
      return
    }
    console.log({
      start: new Date(startDate + " " + startTime),
      end: new Date(endDate + " " + endTime),
      status: "confirmed",
      formId: form.id,
      values: values,
    })
    addBooking({
      start: new Date(startDate + " " + startTime),
      end: new Date(endDate + " " + endTime),
      status: "confirmed",
      formId: form.id,
      values: values,
    }).then(() => {
      getBookings().then((bookings) => {
        this.setState({
          allBookings: bookings,
          bookings,
          showNewBookingDialog: false
        })
      })
    })
  }

  updateBooking() {
    const { startDate, startTime, endDate, endTime, form, values, status } = this.state.updateBooking
    if (!startDate || !startTime || !endDate || !endTime || !form) {
      alert("Bitte alle Felder ausfüllen")
      return
    }
    updateBooking(this.state.updateBooking.id, {
      start: new Date(startDate + " " + startTime),
      end: new Date(endDate + " " + endTime),
      status,
      formId: form.id,
      values: values,
    }).then(() => {
      getBookings().then((bookings) => {
        this.setState({
          allBookings: bookings,
          bookings,
          showUpdateBookingDialog: false
        })
      })
    })
  }

  async componentDidMount() {
    this.setState({
      allBookings: await getBookings(),
      bookings: await getBookings(),
      forms: await getForms(),
      newBooking: {
        ...this.state.newBooking,
        form: (await getForms())[0],
      },
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterMonth !== this.state.filterMonth || prevState.filterYear !== this.state.filterYear) {
      this.setState({
        bookings: this.state.allBookings.filter(booking => {
          if (this.state.filterMonth !== 0 && booking.data.start.toDate().getMonth() + 1 !== this.state.filterMonth) {
            return false
          }
          if (this.state.filterYear !== 0 && booking.data.start.toDate().getFullYear() !== this.state.filterYear) {
            return false
          }
          return true
        })
      })
    }
  }

  getFormByBooking(booking) {
    return this.state.forms.find((f) => f.id === booking.data.formId)
  }

  confirmBooking(bookingId) {
    callConfirmBooking({ bookingId }).then(({ data }) => {
      if (data === "CONFIRMED") {
        getBookings().then((bookings) => {
          this.setState({
            allBookings: bookings,
            bookings,
          })
        })
      }
    })
  }

  rejectBooking(bookingId) {
    callRejectBooking({ bookingId }).then(({ data }) => {
      if (data === "REJECTED") {
        getBookings().then((bookings) => {
          this.setState({
            allBookings: bookings,
            bookings,
          })
        })
      }
    })
  }

  renderNewBookingDialog() {
    if (this.state.forms === null) {
      return null
    }
    return <Modal isOpen={this.state.showNewBookingDialog}>
    <ModalHeader>Neue Buchung</ModalHeader>
    <ModalBody>
      <Row>
        <Col>
        <FormGroup>
      <Label for="exampleDate">
        Startdatum
      </Label>
      <Input
        id="exampleDate"
        name="date"
        placeholder="date placeholder"
        type="date"
        onChange={e => this.setState({
          newBooking: {
            ...this.state.newBooking,
            startDate: e.target.value
          }
        })}
      />
    </FormGroup></Col>
    <Col>
    <FormGroup>
      <Label for="exampleTime">
        Startzeit
      </Label>
      <Input
        id="exampleTime"
        name="time"
        placeholder="time placeholder"
        type="time"
        onChange={e => this.setState({
          newBooking: {
            ...this.state.newBooking,
            startTime: e.target.value
          }
        })}
      />
    </FormGroup></Col>
      </Row>
      <Row>
        <Col>
        <FormGroup>
      <Label for="exampleDate">
        Enddatum
      </Label>
      <Input
        id="exampleDate"
        name="date"
        placeholder="date placeholder"
        type="date"
        onChange={e => this.setState({
          newBooking: {
            ...this.state.newBooking,
            endDate: e.target.value
          }
        })}
      />
    </FormGroup></Col>
    <Col>
    <FormGroup>
      <Label for="exampleTime">
        Endzeit
      </Label>
      <Input
        id="exampleTime"
        name="time"
        placeholder="time placeholder"
        type="time"
        onChange={e => this.setState({
          newBooking: {
            ...this.state.newBooking,
            endTime: e.target.value
          }
        })}
      />
    </FormGroup></Col>
      </Row>
      <Row>
        <Col>
        <FormGroup>
      <Label for="exampleSelect">
        Formular
      </Label>
      <Input type="select" name="select" id="exampleSelect" onChange={e => this.setState({
        newBooking: {
          ...this.state.newBooking,
          form: this.state.forms.find(f => f.id === e.target.value)
        }
      })}>
        {this.state.forms.map((form) => (
          <option key={`form_${form.id}`} value={form.id}>{form.data.name}</option>
        ))}
      </Input>
    </FormGroup>
        </Col>
      </Row>
      {this.state.newBooking.form && this.state.newBooking.form.data.fields.map((field) => (
        <Row key={`field_${field.id}`}>
          <Col>
          <FormGroup>
      <Label for="exampleSelect">
        {field.label}
      </Label>
      <Input id="exampleInput" placeholder={field.label} type="text" onChange={(e) => {
        this.setState({
          newBooking: {
            ...this.state.newBooking,
            values: {
              ...this.state.newBooking.values,
              [field.name]: e.target.value
            }
          }
        })
      }} />
    </FormGroup>
          </Col>
        </Row>
      ))}
      

    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={() => this.createBooking()}>
        Buchung erstellen
      </Button>{' '}
      <Button color="secondary" onClick={() => this.setState({ showNewBookingDialog: false })}>
        Abbrechen
      </Button>
    </ModalFooter>
  </Modal>
  }

  renderUpdateBookingDialog() {
    if (this.state.forms === null) {
      return null
    }
    if (this.state.updateBooking.form === null) {
      return null
    }
    return <Modal isOpen={this.state.showUpdateBookingDialog}>
    <ModalHeader>Buchung bearbeiten</ModalHeader>
    <ModalBody>
      <Row>
        <Col>
        <FormGroup>
      <Label for="exampleDate">
        Startdatum
      </Label>
      <Input
        id="exampleDate"
        name="date"
        placeholder="date placeholder"
        type="date"
        value={this.state.updateBooking.startDate}
        onChange={e => this.setState({
          updateBooking: {
            ...this.state.updateBooking,
            startDate: e.target.value
          }
        })}
      />
    </FormGroup></Col>
    <Col>
    <FormGroup>
      <Label for="exampleTime">
        Startzeit
      </Label>
      <Input
        id="exampleTime"
        name="time"
        placeholder="time placeholder"
        type="time"
        value={this.state.updateBooking.startTime}
        onChange={e => this.setState({
          updateBooking: {
            ...this.state.updateBooking,
            startTime: e.target.value
          }
        })}
      />
    </FormGroup></Col>
      </Row>
      <Row>
        <Col>
        <FormGroup>
      <Label for="exampleDate">
        Enddatum
      </Label>
      <Input
        id="exampleDate"
        name="date"
        placeholder="date placeholder"
        type="date"
        value={this.state.updateBooking.endDate}
        onChange={e => this.setState({
          updateBooking: {
            ...this.state.updateBooking,
            endDate: e.target.value
          }
        })}
      />
    </FormGroup></Col>
    <Col>
    <FormGroup>
      <Label for="exampleTime">
        Endzeit
      </Label>
      <Input
        id="exampleTime"
        name="time"
        placeholder="time placeholder"
        type="time"
        value={this.state.updateBooking.endTime}
        onChange={e => this.setState({
          updateBooking: {
            ...this.state.updateBooking,
            endTime: e.target.value
          }
        })}
      />
    </FormGroup></Col>
      </Row>
      <Row>
        <Col>
        <FormGroup>
      <Label for="exampleSelect">
        Formular
      </Label>
      <Input type="select" name="select" id="exampleSelect" value={this.state.updateBooking.form.id} onChange={e => this.setState({
        updateBooking: {
          ...this.state.updateBooking,
          form: this.state.forms.find(f => f.id === e.target.value)
        }
      })}>
        {this.state.forms.map((form) => (
          <option key={`form_${form.id}`} value={form.id}>{form.data.name}</option>
        ))}
      </Input>
    </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
        <FormGroup>
      <Label for="exampleSelect">
        Status
      </Label>
      <Input type="select" name="select" id="exampleSelect" value={this.state.updateBooking.status} onChange={e => this.setState({
        updateBooking: {
          ...this.state.updateBooking,
          status: e.target.value
        }
      })}>
        <option value="confirmed">Bestätigt</option>
        <option value="pending">Ausstehend</option>
        <option value="rejected">Storniert</option>
      </Input>
    </FormGroup>
        </Col>
      </Row>
      {this.state.updateBooking.form && this.state.updateBooking.form.data.fields.map((field) => (
        <Row key={`field_${field.id}`}>
          <Col>
          <FormGroup>
      <Label for="exampleSelect">
        {field.label}
      </Label>
      <Input id="exampleInput" value={this.state.updateBooking.values[field.name]} placeholder={field.label} type="text" onChange={(e) => {
        this.setState({
          updateBooking: {
            ...this.state.updateBooking,
            values: {
              ...this.state.updateBooking.values,
              [field.name]: e.target.value
            }
          }
        })
      }} />
    </FormGroup>
          </Col>
        </Row>
      ))}
      

    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={() => this.updateBooking()}>
        Buchung ändern
      </Button>{' '}
      <Button color="secondary" onClick={() => this.setState({ showUpdateBookingDialog: false })}>
        Abbrechen
      </Button>
    </ModalFooter>
  </Modal>
  }

  render() {
    const getLabel = (booking, key) => {
      const field = this.getFormByBooking(booking).data.fields.find(
        (f) => f.name === key
      )
      if (field) {
        return field.label
      } else {
        return null
      }
    }

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Buchungen</h3>
                </CardHeader>
                <CardBody>
                <Button
                  onClick={(e) => this.setState({ showNewBookingDialog: true })}
                  title="Neue Buchung"
                >
                  <i className="fas fa-plus" /> Neue Buchung
                </Button>
                <Button
                  onClick={(e) => {
                    getBookings().then((bookings) => {
                      this.setState({
                        allBookings: bookings,
                        bookings,
                      })
                    })
                  }}
                  title="Aktualisieren"
                >
                  <i className="fas fa-redo" /> Aktualisieren
                </Button>
                <Input
      id="exampleSelect"
      name="select"
      type="select"
      style={{width: "150px", float: "right"}}
      onChange={e => {
        this.setState({
          filterMonth: parseInt(e.target.value)
        })
      }}
    >
      <option value="0">Alle Monate</option>
      <option value="1">Jänner</option>
      <option value="2">Februar</option>
      <option value="3">März</option>
      <option value="4">April</option>
      <option value="5">Mai</option>
      <option value="6">Juni</option>
      <option value="7">Juli</option>
      <option value="8">August</option>
      <option value="9">September</option>
      <option value="10">Oktober</option>
      <option value="11">November</option>
      <option value="12">Dezember</option>
    </Input>
    <Input
      id="exampleSelect"
      name="select"
      type="select"
      style={{width: "150px", float: "right"}}
      onChange={e => {
        this.setState({
          filterYear: parseInt(e.target.value)
        })
      }}
    >
      <option value="0">Alle Jahre</option>
      {[...new Set(this.state.allBookings.map(booking => booking.data.start.toDate().getFullYear()))].map((year) => (
        <option value={year}>{year}</option>
      ))}
    </Input>
                {this.renderNewBookingDialog()}
                {this.renderUpdateBookingDialog()}
                </CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      {false && <th scope="col">Formulartyp</th>}
                      <th scope="col">Datum / Uhrzeit</th>
                      <th scope="col">Buchungsdaten</th>
                      <th scope="col">Status</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.bookings.map((booking) => (
                      <tr key={`booking_${booking.id}`}>
                        <th>{booking.data.values.name}</th>
                        {false && (
                          <th>
                            {this.getFormByBooking(booking).data.type ===
                            "hours"
                              ? "Flexible Zeitauswahl"
                              : ""}
                          </th>
                        )}
                        <td>{booking.interval.toString()}</td>
                        <td>
                          {booking.data.values &&
                            Object.keys(booking.data.values).map((k) => (
                              <>
                                <strong>{getLabel(booking, k)}:</strong>{" "}
                                {booking.data.values[k]}
                                <br />
                              </>
                            ))}
                        </td>
                        <td>
                          {booking.data.status === "pending" && (
                            <Badge color="warning">ausstehend</Badge>
                          )}
                          {booking.data.status === "confirmed" && (
                            <Badge color="success">bestätigt</Badge>
                          )}
                          {booking.data.status === "rejected" && (
                            <Badge color="danger">storniert</Badge>
                          )}
                        </td>
                        <td className="text-right">
                          {booking.data.status === "pending" && (
                            <>
                              <Button
                                onClick={(e) => this.confirmBooking(booking.id)}
                                title="Buchung betätigen"
                              >
                                <i className="fas fa-check" />
                              </Button>
                              <Button
                                onClick={(e) => this.rejectBooking(booking.id)}
                                title="Buchung stornieren"
                              >
                                <i className="fas fa-times" />
                              </Button>
                              
                            </>
                          )}
                          <Button
                                onClick={(e) => {
                                  this.setState({
                                  showUpdateBookingDialog: true,
                                  updateBooking: {
                                    id: booking.id,
                                    status: booking.data.status,
                                    startDate: booking.data.start.toDate().toISOString().split("T")[0],
                                    startTime: booking.data.start.toDate().toISOString().split("T")[1].split(".")[0],
                                    endDate: booking.data.end.toDate().toISOString().split("T")[0],
                                    endTime: booking.data.end.toDate().toISOString().split("T")[1].split(".")[0],
                                    form: this.getFormByBooking(booking),
                                    values: booking.data.values
                                  }
                                })
                                }}
                                title="Buchung bearbeiten"
                              >
                                <i className="fas fa-edit" />
                              </Button>
                          {false && booking.data.status === "pending" && (
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                {booking.data.status === "pending" && (
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) =>
                                      this.confirmBooking(booking.id)
                                    }
                                  >
                                    Buchung bestätigen
                                  </DropdownItem>
                                )}
                                {booking.data.status === "pending" && (
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Buchung ablehnen
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {false && (
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="active">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            1
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            2 <span className="sr-only">(current)</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            3
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  )}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

export default Bookings
