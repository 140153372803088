/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Register from "views/examples/Register.js"
import Login from "views/examples/Login.js"
import Bookings from "views/Bookings"
import Contacts from "views/Contacts"
import FormSettings from "views/FormSettings"
import Forms from "views/Forms"

var routes = [
  {
    path: "/bookings",
    name: "Buchungen",
    icon: "ni ni-bullet-list-67 text-red",
    component: Bookings,
    layout: "/admin",
    sidebar: true,
  },
  /*{
    path: "/contacts",
    name: "Kontakte",
    icon: "ni ni-books text-red",
    component: Contacts,
    layout: "/admin",
    sidebar: true,
  },*/
  {
    path: "/forms/:id",
    name: "Formulare",
    icon: "ni ni-books text-red",
    component: FormSettings,
    layout: "/admin",
    sidebar: false,
  },
  {
    path: "/forms",
    name: "Formulare",
    icon: "ni ni-books text-red",
    component: Forms,
    layout: "/admin",
    sidebar: true,
  },
  /*{
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  },*/
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    sidebar: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    sidebar: false,
  },
]
export default routes
