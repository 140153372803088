// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth"
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  setDoc,
  getDoc,
  orderBy,
} from "firebase/firestore"
import { DateTimeInterval } from "./DateUtils"
import { getFunctions, httpsCallable } from "firebase/functions"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

export const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    })
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert("Password reset link sent!")
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

export const logout = () => {
  signOut(auth)
}

export const addForm = async (name, fields, type) => {
  return await addDoc(collection(db, "forms"), {
    fields,
    name,
    type,
  })
}

export const saveForm = async (id, data) => {
  await setDoc(doc(db, "forms", id), data)
}

export const addBooking = async (bookingData) => {
  return await addDoc(collection(db, "bookings"), bookingData)
}

export const updateBooking = async (id, data) => {
  await setDoc(doc(db, "bookings", id), data)
}

export const getBookings = async () => {
  const q = query(collection(db, "bookings"), orderBy("start", "desc"))
  const bookings = await getDocs(q)
  return bookings.docs.map((d) => {
    return {
      id: d.id,
      data: d.data(),
      interval: new DateTimeInterval(
        d.data().start.toDate(),
        d.data().end.toDate()
      ),
    }
  })
}

export const addBlockedTime = async (start, end) => {
  return await addDoc(collection(db, "blocked_times"), {
    start,
    end,
  })
}

export const getUser = async (uid) => {
  const q = query(collection(db, "users"), where("uid", "==", uid))
  const users = await getDocs(q)
  if (users.docs.length === 0) {
    return null
  }
  return users.docs[0].data()
}

export const getForm = async (id) => {
  const docRef = doc(db, "forms", id)
  const docSnap = await getDoc(docRef)
  const form = await docSnap.data()
  return form
}

export const getForms = async () => {
  const q = query(collection(db, "forms"))
  const forms = await getDocs(q)
  return forms.docs.map((d) => {
    return {
      id: d.id,
      data: d.data(),
    }
  })
}

export const getBlockedTimes = async () => {
  const q = query(collection(db, "blocked_times"))
  const forms = await getDocs(q)
  return forms.docs.map((d) => {
    return {
      id: d.id,
      data: d.data(),
    }
  })
}

const functions = getFunctions(app, "europe-west1")
export const callConfirmBooking = httpsCallable(functions, "callConfirmBooking")
export const callRejectBooking = httpsCallable(functions, "callRejectBooking")
